<!--
 Copyright (C) 2024 bAvenir

 This program and the accompanying materials are made
 available under the terms of the Eclipse Public License 2.0
 which is available at https://www.eclipse.org/legal/epl-2.0/

 SPDX-License-Identifier: EPL-2.0
-->

<template>
  <div class="w-screen h-dvh">
    <router-view />
  </div>
</template>

<style lang="scss">
</style>
